.scroll-container, textarea {
    overflow-y: auto !important;
  }
  
  .scroll-container::-webkit-scrollbar, textarea::-webkit-scrollbar {
    width: 7px;
  }
  
  
  .scroll-container::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
    background: transparent;
  }
  
  
  .scroll-container::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  
  .scroll-container::-webkit-scrollbar-thumb:hover, textarea::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-container::-webkit-scrollbar:hover , textarea::-webkit-scrollbar:hover {
    width: 10px !important;
  }

