.fileName span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 800;
}

.verticalScroller {
    overflow-y: auto !important;
  }
  
  .verticalScroller::-webkit-scrollbar {
    width: 7px;
  }
  
  
  .verticalScroller::-webkit-scrollbar-track {
    background: transparent;
  }
  
  
  .verticalScroller::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  
  .verticalScroller::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .verticalScroller::-webkit-scrollbar:hover {
    width: 10px !important;
  }

  .MuiPaper-root {
    box-shadow: rgba(90, 90, 90, 0.4) 0 1px 10px !important; 
  }

   .ContextRightClickMenu  .MuiPaper-root{
    box-shadow: rgba(90, 90, 90, 0.1) 0 1px 3px !important;
    } 

    .popover {
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      border-radius: 9px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .notLicencedIconSeries {
      position: relative;
      top: 0px;
      right: -10px;
      z-index: 10;
      opacity: 1;
  }

  .color-dot-animation {
    text-align: center;
    position: relative;
    padding: 0;
    margin: 0;
    padding-left: 0px;
  }

  .color-dot-animation:hover {
    animation: pulse 1.8s infinite;
    border-radius: 50%;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
  
    70% {
      transform: scale(1.3);
    }
  
    100% {
      transform: scale(0.95);
    }
  }