.licenses {
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	display: flex;
	max-width: 100%;
	margin: 20px;
    margin-left: 0;
	overflow: hidden;
	width: 700px;
}

.licenses h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.licenses h2 {
	letter-spacing: 1px;
	margin: 10px 0;
}

.licenses-preview {
	background-color: #32992c;
	color: #fff;
	padding: 30px;
	max-width: 350px;
    min-width: 250px;
}

.licenses-preview h2 {
	font-size: 18px;
    margin-top: 30px;
}

.licenses-preview span {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	margin-top: 30px;
	text-decoration: none;
}

.licenses-info {
	padding: 30px;
	position: relative;
	width: 100%;
}

.progress-container {
	position: absolute;
	top: 30px;
	right: 30px;
	text-align: right;
	width: 150px;

}

.progress {
	background-color: #ddd;
	border-radius: 3px;
	height: 5px;
	width: 100%;
}

.progress::after {
	border-radius: 3px;
	background-color: #32992c;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 5px;
	width: 66%;
}

.progress-text {
	font-size: 10px;
	opacity: 0.6;
	letter-spacing: 1px;
}

.btn {
	background-color: #32992c !important;
	border: 0 !important;
	border-radius: 10px !important;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2) !important;
	color: #fff !important;
	position: absolute !important;
	bottom: 30px !important;
	right: 30px !important;
	letter-spacing: 1px !important;
}
