h1 {
    font-family: "proxima-nova",sans-serif;
    letter-spacing: -0.01em;
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    margin-left: -3px;
    line-height: 1em;
    color: #000;
    margin-bottom: 8px;
    text-rendering: optimizeLegibility;
}

.thirdPartyTable {
    width: 100%;
}

.thirdPartyTable, .thirdPartyTable th, .thirdPartyTable td {
    border: 1px solid rgba(200, 200, 200, 0.87);
    border-collapse: collapse;
}

.thirdPartyTable th {
    background-color: #32992c;
    color: #fff
}

.thirdPartyTable th, .thirdPartyTable td {
    padding: 15px;
}