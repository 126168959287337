@font-face {
  font-family: 'Deprag';
  src: local('./Layout/CustomFonts/Smack Laideth Down 2019.otf'), url(./Layout/CustomFonts/Smack\ Laideth\ Down\ 2019.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Logo';
  src: local('./Layout/CustomFonts/TrainOne-Regular.ttf'), url(./Layout/CustomFonts/TrainOne-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiCard-root {
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
}

.MuiStepIcon-root.Mui-completed {
  /* color: #32992c !important; */
}

.Mui-active {
  /* color: #264d23 !important; */
}

.MuiStepIcon-text {
  fill: #fff !important;
}

.MuiStepLabel-labelContainer span {
  font-size: 1.3em !important;
  font-weight: 200 !important;
}


.MuiContainer-root {
  padding-left: 0;
  padding-right: 0;
}

.MuiStepLabel-root{
  text-align: left !important;
  align-items: flex-start !important;
}

.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  text-align: left !important;
  margin-top: 5px !important;
}

.MuiStepContent-root {
  margin-left: 10px !important;
  padding-left: 40px !important;
  padding-right: 8px;
  border-left: 2px solid #bdbdbd !important;
}

.scichart__rollover-tooltip rect {
  box-shadow: none !important;
  filter: none !important;
  opacity: 0.75 !important;
}

.clearGraphViewer {
  opacity: 0.5;
  z-index: 999; 
  background: rgba(255,255,255,1); 
}

.clearGraphViewer:hover {
  opacity: 1;
}