.userGuide--modal {
  z-index: 10001;
  color: #444;
}

.userGuide--modal div {
  border-radius: 10px;
}

.userGuide--modal h1 {}

.userGuide--modal p {}

.userGuide--modal button {
  min-width: 100px;
  height: 50px;
  border-radius: 10px;
  padding: 8px;
  margin-right: 8px;
  border: 0px;
  color: #444;
  cursor: pointer;
  transition: all .3s ease-in-out;
}


.userGuide--modal button:first-child {
  background: #edd2ce;
}

.userGuide--modal button:first-child:hover {
  background: #f03e3e;
  color: #fff;
}

.userGuide--modal button:nth-child(2) {
  background: #cbf0c9
}

.userGuide--modal button:nth-child(2):hover {
  background: #32992c;
  color: #fff;
}


.userGuide--mask {
  z-index: 10001;
  background-color: rgba(0, 0, 0, 0.4);
  color: #444;
}

.userGuide--message {
  border-radius: 10px;
  min-width: 400px;
}

.userGuide--message h3 {}

.userGuide--message p {}


.userGuide--message button {
  min-width: 100px;
  height: 50px;
  border-radius: 10px;
  padding: 8px;
  margin-right: 8px;
  border: 0px;
  color: #444;
  cursor: pointer;
  transition: all .3s ease-in-out;
}


.userGuide--message button.skip {
  background: #edd2ce;
}

.userGuide--message button.skip:hover {
  background: #f03e3e;
  color: #fff;
}

.userGuide--message button.next, .userGuide--message button.finish {
  background: #cbf0c9
}

.userGuide--message button.next:hover, .userGuide--message button.finish:hover {
  background: #32992c;
  color: #fff;
}