.image-gallery {
    width: 700px;
    height: "auto";
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}


.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-bullets .image-gallery-bullets-container button.active  {
    background-color: #009933;
 }

.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-bullets .image-gallery-bullets-container button  {
    background-color: #646464;
 }

 .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 70px !important;
    width: 60px !important;
}

@media (hover: hover) and (pointer: fine) {
.image-gallery-icon:hover {
    color: #32992c !important;
}
}
