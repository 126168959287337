.cardWrapper {
    /* display: inline-block; */
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.layer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 300px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 100%;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.cardWrapper:hover .layer {
    top: 0;
}

.cardWrapper:hover .layer>button {
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.popover-feedback {
    opacity: 0.5 !important;
    transition: all 0.3s ease-in-out !important;
}

.popover-feedback:hover {
    opacity: 1 !important;
}

@media screen and (max-width: 767px) {
    .feedbackDrawerContent {
        max-width: 80vw;
    }
}

@media screen and (min-width: 768px) {
    .feedbackDrawerContent {
        max-width: 600px;
    }
}