#sciChart {
    width: calc(100%);
    height: 100%;
}

.home-scichart-full-container {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    background: #fff !important;
    z-index: 99999 !important;
}

.home-scichart-container {
    position: relative;
}

#sciChartOverview {
    width: 100%;
    height: 40px !important;
}

.chartFullButton {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    opacity: 1;
    float: right;
}

.chartFullButton:hover {
    background: #e3e3e3 !important;
    opacity: 1;
}