.notLicencedIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 10;
}

.buyLicenseButton:hover {
    background: #fff !important;
    border-color: #fff !important;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.3);
}